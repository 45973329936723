import React from 'react';
import Layout from '../components/layout';

const IndexPage = () => (
  <Layout>
    <h1>Welcome</h1>
    <p>Download the app. Do it I say!</p>
    <p>This site is still under construction.</p>
    <p style={{ fontSize: '11px', color: 'rgba(0,0,0,0.6)' }}>
      <i>
        This app and site are not associated with DigitalOcean. They are build
        and hosted by{' '}
        <a
          style={{ textDecoration: 'none', color: '#0071e0' }}
          href="https://johnbwoodruff.com"
        >
          John Woodruff
        </a>
        .
      </i>
    </p>
  </Layout>
);

export default IndexPage;
